/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

import { createApp } from 'vue';
import passportClients from './components/passport/Clients';
import passportAuthorizedClients from './components/passport/AuthorizedClients';
import passportPersonalAccessTokens from './components/passport/PersonalAccessTokens';

const app = createApp({});

app.component("passport-clients", passportClients);
app.component('passport-authorized-clients', passportAuthorizedClients)
app.component('passport-personal-access-tokens', passportPersonalAccessTokens)

app.mount("#app")

var swal = (window.swal = require("sweetalert"));
